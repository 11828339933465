<template>
  <div class="rma-addmessage position-relative">
    <absolute-loader :showLoader="callLoader"></absolute-loader>
    <div class="rma-addmessage_msg form-group">
      <label v-t="'account.rma.informationPlus'"></label>
      <base-textarea
        class="wrapp-textarea"
        name="rmaMessage"
        v-model="message"
        :placeholder="$t('rma.addMessage')"
      />
    </div>
  </div>
</template>

<script>
import BaseTextarea from '@/components/Form/BaseTextarea'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'

export default {
  props: {
    rmaId: {
      type: String,
      default: ''
    },
    reply: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    callLoader: false
  }),
  computed: {
    message: {
      get () {
        return this.reply
      },
      set (newValue) {
        this.$emit('update:reply', newValue)
      }
    }
  },
  components: {
    BaseTextarea,
    AbsoluteLoader
  }
}
</script>

import { render, staticRenderFns } from "./ListMessage.vue?vue&type=template&id=feb18fc6&scoped=true&"
import script from "./ListMessage.vue?vue&type=script&lang=js&"
export * from "./ListMessage.vue?vue&type=script&lang=js&"
import style0 from "./ListMessage.vue?vue&type=style&index=0&id=feb18fc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feb18fc6",
  null
  
)

component.options.__file = "ListMessage.vue"
export default component.exports
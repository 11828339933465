<template>
  <div class="table-tile" :title="item.name">
    <div class="table-tile__item">
      <div class="table-tile__item-block block-media">
        <img-product
        class="wrapp-image"
        :imgPath="image"
        :alt="item.name"
        :styleImage="{ maxWidth: '100%' }"
        />
      </div>
      <div class="table-tile__item-block block-name">
        <span class="label" v-html="item.name"></span>
      </div>
      <div class="table-tile__item-block block-type">
        <span class="value">{{ resolution }}</span>
      </div>
      <div class="table-tile__item-block block-reason">
        <span class="value">{{ reason }}</span>
      </div>
      <div class="table-tile__item-block block-condition">
        <span class="value">{{ reasonDetail }}</span>
      </div>
      <div class="table-tile__item-block block-qty">
        <div class="label">{{ $t('listItem.quantity') }}</div>
        <span class="value t-regular">{{ item.qty }}</span>
      </div>
      <div class="table-tile__item-block block-subtotal block-latest">
        <span class="value f-bold">{{ subtotal | price($store) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Config from 'config'
import { find } from 'lodash'
import ImgProduct from 'theme/components/Product/Image'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    image () {
      return this.item.extension_attributes.image_url
    },
    resolution () {
      let resolution = find(this.item.custom_fields, { field_name: 'Resolution' })
      if (resolution) {
        return resolution.value
      } else {
        return '/'
      }
    },
    reason () {
      let reason = find(this.item.custom_fields, { field_name: 'Reason' })
      if (reason) {
        return reason.value
      } else {
        return '/'
      }
    },
    reasonDetail () {
      let reasonDetail = find(this.item.custom_fields, { field_name: 'Reason detail' })
      if (reasonDetail) {
        return reasonDetail.value
      } else {
        return '/'
      }
    },
    subtotal () {
      let subtotal = 0
      if (this.item) {
        let item = this.item
        let productPrice = Config.Theme.priceIncludeTax ? item.base_price_incl_tax : item.base_price
        subtotal = productPrice * item.qty
      }
      return subtotal
    }
  },
  components: {
    ImgProduct
  }
}
</script>

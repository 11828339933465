import { render, staticRenderFns } from "./MyReturnsEdit.vue?vue&type=template&id=4ca5ce2b&"
import script from "./MyReturnsEdit.vue?vue&type=script&lang=js&"
export * from "./MyReturnsEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MyReturnsEdit.vue"
export default component.exports